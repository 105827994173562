<template>
  <section id="new-connection">
    <b-row class="match-height">
      <b-col cols="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              Подключение Google Analytics
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-col cols="4">
              <v-select
                id="account-select"
                v-model="account"
                :options="accountOptions"
                class="mb-1 mt-1"
                label="text"
                placeholder="Выберите необходимый аккаунт..."
              >
                <template v-slot:no-options>
                  Ничего не найдено
                </template>
              </v-select>
              <v-select
                id="property-select"
                v-model="webProperty"
                :options="webPropertyOptions"
                class="mb-1 mt-1"
                label="text"
                placeholder="Выберите необходимый ресурс..."
              >
                <template v-slot:no-options>
                  Ничего не найдено
                </template>
              </v-select>
              <v-select
                id="profile-select"
                v-model="profile"
                :options="profileOptions"
                class="mb-1 mt-1"
                label="text"
                placeholder="Выберите необходимый профиль..."
              >
                <template v-slot:no-options>
                  Ничего не найдено
                </template>
              </v-select>
            </b-col>
            <transition
              name="fade"
              mode="out-in"
            >
              <b-col
                v-if="profile != null"
                cols="8"
              >
                <div
                  class="mt-1"
                  style="display: inline-flex;"
                >
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    size="sm"
                    :disabled="isEcommerce"
                    @click="getGoalsByProfileId"
                  >
                    Получить цели счетчика
                  </b-button>
                  <span class="ml-1">или</span>
                  <b-form-checkbox
                    v-model="isEcommerce"
                    class="ml-1"
                    value="A"
                  >
                    Проект является E-commerce?
                  </b-form-checkbox>
                </div>
              </b-col>
            </transition>
            <transition
              name="fade"
              mode="out-in"
            >
              <b-col
                v-if="profile != null && !isEcommerce"
                class="mt-2"
                cols="6"
              >
                <h5>Цели</h5>
                <b-table
                  :data="goalsData"
                  :checked-rows.sync="checkedGoals"
                  :paginated="true"
                  checkable
                  class="mb-1 mt-1"
                  :checkbox-position="checkboxPosition"
                >
                  <b-table-column
                    v-slot="props"
                    field="name"
                    searchable
                    label="Название"
                  >
                    {{ props.row.name }}
                  </b-table-column>

                  <b-table-column
                    v-slot="props"
                    field="type"
                    label="Тип"
                  >
                    {{ props.row.type }}
                  </b-table-column>
                  <template #bottom-left>
                    <b>Выбрано целей</b>: {{ checkedGoals.length }}
                  </template>
                </b-table>
              </b-col>
            </transition>
            <b-col cols="4">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="mt-1"
                variant="outline-primary"
                @click="saveIntegration()"
              >
                Сохранить выбор
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BOverlay,
  BSpinner,
  BCardTitle,
  BFormCheckbox,
  BCol,
  BRow,
  VBToggle, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import OpenIndicator from '@core/components/vue-select/OpenIndicator.vue'

vSelect.props.components.default = () => ({ OpenIndicator })

export default {
  components: {
    BCard,
    BButton,
    vSelect,
    BCardHeader,
    BCardBody,
    BOverlay,
    BSpinner,
    BFormCheckbox,
    BCardTitle,
    BRow,
    BCol,
  },
  directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
  props: {
    connectionId: [String, Number],
  },
  data() {
    return {
      checkboxPosition: 'left',
      showRedirect: false,
      account: null,
      webProperty: null,
      profile: null,
      webPropertyOptions: [],
      checkedGoals: [],
      profileOptions: [],
      accountOptions: [],
      goalsData: [],
      googleTokens: [],
      isEcommerce: false,
    }
  },
  computed: {
  },
  watch: {
    account() {
      this.webProperty = null
      this.webPropertyOptions = []

      this.profileOptions = []
      this.profile = null

      this.$gapi.getGapiClient().then(gapi => {
        gapi.client.analytics.management.webproperties.list({ accountId: this.account.value }).then(properties => {
          if (properties.result.items && properties.result.items.length) {
            const propertiesParse = properties.result.items
            propertiesParse.forEach(element => this.webPropertyOptions.push({
              value: element.id,
              text: element.name,
            }))
          }
        })
      })
    },
    webProperty() {
      this.profileOptions = []
      this.profile = null

      this.$gapi.getGapiClient().then(gapi => {
        gapi.client.analytics.management.profiles.list({ accountId: this.account.value, webPropertyId: this.webProperty.value })
          .then(profiles => {
            if (profiles.result.items && profiles.result.items.length) {
              const profilesParse = profiles.result.items
              profilesParse.forEach(element => this.profileOptions.push({
                value: element.id,
                text: element.name,
              }))
            }
          })
      })
    },
  },
  mounted() {
    this.$gapi.getGapiClient().then(gapi => {
      gapi.auth2.getAuthInstance().grantOfflineAccess().then(res => {
        console.log(res, 'res')
        this.$http.get(`google-analytics/token?code=${res.code}`)
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            console.log(response, 'token')
            this.googleTokens = response.data
          })
        gapi.client.load('analytics', 'v3', () => {
          gapi.client.analytics.management.accounts.list().then(accounts => {
            if (accounts.result.items && accounts.result.items.length) {
              const accountsParse = accounts.result.items
              accountsParse.forEach(element => this.accountOptions.push({
                value: element.id,
                text: element.name,
              }))
            }
          })
        })
      })
    })
  },
  created() {
  },
  methods: {
    saveIntegration() {
      const connId = this.$route.params.connectionId

      // TO-DO: NEED refactoring
      const obj = {
        refreshToken: this.googleTokens.refreshToken,
        accessToken: this.googleTokens.accessToken,
        accessTokenExpirationDate: this.googleTokens.accessTokenExpirationDate,
        connectionId: connId,
        type: 'GOOGLE_ANALYTICS',
        info: JSON.stringify({
          viewId: this.profile.value,
          accountId: this.account.value,
          propertyId: this.webProperty.value,
          goals: JSON.stringify(this.checkedGoals),
          goalIds: this.checkedGoals.map(c => c.id).join(','),
          isTransactions: this.isEcommerce,
        }),
      }
      //  this.saveLoading = true
      this.$http.post('integration/fill', obj)
      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$router.push({
            name: 'new-connection',
            params: { connectionId: connId },
          })
        })
    },
    getGoalsByProfileId() {
      this.goalsData = []

      this.$gapi.getGapiClient().then(gapi => {
        gapi.client.analytics.management.goals.list({
          accountId: this.account.value,
          webPropertyId: this.webProperty.value,
          profileId: this.profile.value,
        })
          .then(goals => {
            if (goals.result.items && goals.result.items.length) {
              const goalsParse = goals.result.items
              goalsParse.forEach(element => this.goalsData.push({
                id: element.id,
                name: element.name,
                type: element.type,
              }))
            }
          })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.b-table::v-deep .pagination-list {
  margin-top: 20px!important;
}
</style>
